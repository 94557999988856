import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appState } from "./Api/parameters";
import "./App.css";
import MobileLayout from "./components/MobileLayout/Layout";
import DesktopLayout from "./components/DesktopLayout/Layout";
import { AppState, AppStateType } from "./enums/appState.enum";
import LoginPage from "./pages/Login/LoginPage";
import { setServerError, updateParametersData } from "./reduxSlices/parametersSlice";

import {
  selectStudent,
  selectToken,
  setCarrearActual,
  setListadoCarreras,
  setStudentBloqueoActivo,
  setStudentBloqueoMensajes,
  setStudentInicializado,
  setStudentMostrarHorarios,
  setStudentPrioridad,
  setToken,
  updateStudentData,
} from "./reduxSlices/studentSlice";
import {
  selectInscribedSubjects,
  setFilterTagsList,
  setInscribedSubjects,
  setLoadingRequestInTransit,
  setOfferedSubjects,
  setPinnedSubjectsList,
  setSubjectsQuota,
} from "./reduxSlices/subjectsSlice";

//@ts-ignore
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import Warning from "./components/Warning/Warning";
import { auth, getEstadoAlumno, login } from "./Api/authentication";
import { getMateriasAlumno, getMateriasFijadasAlumno, getMateriasInscriptasAlumno } from "./Api/subject";
import { EstadoLoginStudent, ServerError } from "./types";
import { getCuposAlumno } from "./Api/reservation";
import { EstadoAlumnoCarrera } from "./enums/alumnoCarrera.enum";
import WebsocketManager from "./components/WebsocketManager/WebsocketManager";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import { useAppDispatch } from "./app/store";
import { getModoCarritoParam } from "./reduxAsyncThunks/parameters";
import useMediaQueries from "./hooks/useMediaQuery";
import { updateCupoCursosList } from "./reduxAsyncThunks/subjects";
import { StorageKeys } from "./utils/localStorage";
import DesktopMain from "./components/DesktopMain/DesktopMain";
import BlockedPage from "./pages/BlockedPage";
import UnknownUserPage from "./pages/UnknownUserPage";
import { updatePrioridad } from "./Api/prioridad";
import MobileCursos from "./pages/MobileCursos";
import MobileCalendario from "./pages/MobileCalendario";
import { dividirLista } from "./utils/list";
import {
  selectFlags,
  selectInvalidarSemaforoSocketCount,
  setInvalidarSemaforoSocketCount,
  setLastUpdateOferta,
} from "./reduxSlices/globalFlagsSlice";

function App() {
  setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/");
  const student = useSelector(selectStudent);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const dispatch = useAppDispatch();
  const invalidarSemaforoSocketCount = useSelector(selectInvalidarSemaforoSocketCount);
  const { isLogginProcess } = useSelector(selectFlags);

  const [sessionExpired, setSessionExpired] = useState(false);
  const token = useSelector(selectToken);
  const invalidarSemaforoSocketCountLocal = useRef(invalidarSemaforoSocketCount);

  useEffect(() => {
    invalidarSemaforoSocketCountLocal.current = invalidarSemaforoSocketCount; // Actualiza referencia local
  }, [invalidarSemaforoSocketCount]);

  const getFlagParameters = async () => {
    await dispatch(getModoCarritoParam());
  };

  const getToken = () => {
    const tkn = localStorage.getItem("token");
    if (tkn) {
      dispatch(setToken(tkn));
    }
  };
  // Refresh token
  const saveToken = async (token: string) => {
    localStorage.setItem("token", token);
    await dispatch(setToken(token));
  }

  const saveIdAlumnoPrograma = (idAlumnoPrograma: number) => {
    localStorage.setItem("idAP", JSON.stringify(idAlumnoPrograma));
  }

  const checkBloqueos = async (email: string) => {
    const data: EstadoLoginStudent = await login(email);
    const { token, carreras } = data;

    await saveToken(token);
    if ("error" in data) {
      dispatch(setServerError(true));
    } else {
      await dispatch(setStudentBloqueoActivo(data.bloqueado));
      await dispatch(
        setStudentBloqueoMensajes({
          mensaje: data.mensajeBloqueo,
          subMensaje: data.contactoBloqueo,
        })
      );

      await dispatch(setListadoCarreras(data.carreras));

      const idAlumnoPrograma = carreras && carreras.length > 0 ? carreras[0]?.idAlumnoPrograma : undefined;
      let nombreCarrera = "";
      if (data?.cantidadCarreras > 1) {
        const idAP = localStorage.getItem("idAP");
        if (idAP) {
          nombreCarrera = data.carreras.find((c) => c.idAlumnoPrograma === Number(idAP)).descripcionCarrera;
        } else {
          // primer ingreso post inicializacion
          saveIdAlumnoPrograma(idAlumnoPrograma);
          nombreCarrera = data.carreras[0].descripcionCarrera || "";
        }
      } else {
        saveIdAlumnoPrograma(idAlumnoPrograma);
        nombreCarrera = data?.carreras[0]?.descripcionCarrera || "";
      }
      if (nombreCarrera) await dispatch(setCarrearActual(nombreCarrera));
      if (data?.mostrarHorarios !== null || data?.mostrarHorarios !== undefined) {
        await dispatch(setStudentMostrarHorarios(data.mostrarHorarios));
      }
    }
  };

  const updatePrioridadOnRefresh = async () => {
    const response = await updatePrioridad(student.prioridad);
    await dispatch(
      setStudentPrioridad({ prioridad: response.tienePrioridad, mensajePrioridad: response.mensajePrioridad })
    );
  };

  // detener la recarga a menos que confirme
  // nota: no se puede cambiar el mensaje
  // por ahora queda comentado!
  // useEffect(() => {
  //   window.onbeforeunload = () => {
  //     return "";
  //   };
  //   return () => {
  //     window.onbeforeunload = null;
  //   };
  // }, []);

  useEffect(() => {
    getToken();
    getFlagParameters();
  }, []);

  const getUserId = async () => {
    try {
      const alumno = await auth();
      await checkBloqueos(alumno.email);

      if (alumno.error) {
        console.log("ERROR en la restpuuesta de Auth");

        dispatch(setServerError(true));
        throw new Error("Error de consulta.");
      }

      dispatch(
        updateStudentData({
          id: alumno.id,
          email: alumno.email,
          completeName: {
            name: alumno.nombre,
            lastName: alumno.apellido,
          },
        })
      );
    } catch (error) {
      setSessionExpired(true);
    }
  };

  const checkUserInitAndGetStudentData = async () => {
    let result;
    try {
      const res = await getEstadoAlumno();

      if (res?.error?.response?.status === 401) {
        setSessionExpired(true);
        dispatch(setStudentInicializado(0));
        return false;
      }
      if (res) {
        const { estado } = res;
        if (estado === EstadoAlumnoCarrera.KV_INICIALIZADO) {
          await getUserId();
          await dispatch(setStudentInicializado(2));
          result = true;
        } else if (estado === EstadoAlumnoCarrera.INICIALIZACION_KV_PENDIENTE) {
          result = false;
          await dispatch(setStudentInicializado(1));
        }
      } else {
        localStorage.clear();
        result = false;
        await dispatch(setStudentInicializado(0));
      }
    } catch (err) {
      console.error("ERROR :>>", err);
      result = false;
      dispatch(setStudentInicializado(2));
      setSessionExpired(true);
    }
    return result;
  };

  /* -------------------------------------------------------------------------- */
  /*                                  APP Mount                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // getToken();
    /* ----------------------------- set parameters ----------------------------- */
    appState()
      .then((res) => {
        if (Object.values(AppState).includes(res)) {
          dispatch(
            updateParametersData({
              appState: res as AppStateType,
              started: true,
            })
          );
        } else {
          console.error("Estado no válido");
          dispatch(setServerError(true));
        }
      })
      .catch((err) => {
        console.log("ERROR :>>", err);
      });
  }, []);

  useEffect(() => {    
    /* ------------------------------- set session ------------------------------ */
    if (token?.length > 5 && !isLogginProcess) {      
      checkUserInitAndGetStudentData(); // set session when userState change
    }
    
    let intervalId: any;
    if (student.studentInitState !== 2 && token?.length > 5 && !isLogginProcess) {
      intervalId = setInterval(async () => {
        const initOk = await checkUserInitAndGetStudentData();
        if (initOk) {
          clearInterval(intervalId);
        }
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [token.length, student.studentInitState, isLogginProcess]);

  /* -------------------------------------------------------------------------- */
  /*                           If USER: SET STUDENT STORE DATA                  */
  /* -------------------------------------------------------------------------- */
  function getUniqueTags(subjects: any) {
    const tagSet = new Set();

    subjects.forEach((subject: any) => {
      subject.sections.forEach((section: any) => {
        if (section.tag) {
          const list = section.tag.split(",");
          list.forEach((element: any) => {
            tagSet.add(element.trim());
          });
        }
      });
    });

    return Array.from(tagSet).sort();
  }

  const getStoredSubjects = async () => {
    /* ----------------------------- Set Inscriptas ----------------------------- */
    const inscriptas = await getMateriasInscriptasAlumno(student.id);

    if (inscriptas.status === 200) {
      dispatch(setInscribedSubjects(inscriptas.data));
    }
    /* ------------------------------- Set Oferta ------------------------------- */
    const oferta = await getMateriasAlumno(student.id.toString());

    if (oferta.status === 200) {
      //Generate tags list
      const uniqueTags = getUniqueTags(oferta.data);
      if (uniqueTags.length > 0) {
        dispatch(setFilterTagsList(uniqueTags));
      }
      dispatch(setOfferedSubjects(oferta.data));
    }
    /* ------------------------------- Set Fijadas ------------------------------ */
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    }
    dispatch(setLastUpdateOferta(new Date().getTime()));
  };

  const getSubjectQuotas = async () => {
    const cuposRequestBatchSize = 25;
    const results = await getCuposAlumno(student.id.toString());
    if (results && results.status === 200) {
      dispatch(setSubjectsQuota(results.data));
      if (results.response) {
        const synced = localStorage.getItem(StorageKeys.CupoCursosSynced);
        if (synced !== "true") {
          console.log("%capp ACTUALIZANDO CUPOS ⏳", "color: cyan; text-decoration: underline;");
          let promises: any[] = [];
          let cupoIds: number[] = [];
          for (const alumnoCupo of results.response) {
            if (alumnoCupo.cantidad !== -1 && alumnoCupo.idCupo !== -1) {
              cupoIds.push(alumnoCupo.idCupo);
              // promises.push(dispatch(updateCupoCurso(alumnoCupo.idCupo)));
            }
          }
          console.log(`Solicitando ${cupoIds.length} cupos`);
          let cupoIdsBatchs: number[][] = dividirLista(cupoIds, cuposRequestBatchSize);
          for (const cupoIdsBatch of cupoIdsBatchs) {
            promises.push(dispatch(updateCupoCursosList(cupoIdsBatch)));
            //await dispatch(updateCupoCursosList(cupoIdsBatch));
          }
          await Promise.all(promises);
          console.log("%capp CUPOS ACTUALIZADOS✅", "color: green; text-decoration: underline;");
          if (invalidarSemaforoSocketCountLocal.current > 0) {
            console.log(`FLAG UNSYNCHRONIZED QUOTAS 🛑 - socketCount: ${invalidarSemaforoSocketCountLocal.current}`);
            dispatch(setInvalidarSemaforoSocketCount(0));
          } else {
            console.log("%capp-FLAG CUPOS SYNCED✅", "color: green; text-decoration: underline;");
            localStorage.setItem(StorageKeys.CupoCursosSynced, "true");
          }
        }
      }
    }
  };

  const handleOnStudentInitApp = async () => {
    if (student.id && !student.bloqueoActivo) {
      /* ---------------------------- UPDATE PRIORIDAD ---------------------------- */

      await updatePrioridadOnRefresh();
      /* ----------------------------- STORE SUBJECTS ----------------------------- */
      // Inscribed and Offerred
      getStoredSubjects();
      /* ------------------------------- Set CUOTAS ------------------------------- */
      //  Set quotas state
      getSubjectQuotas();
    }
  };

  useEffect(() => {

    handleOnStudentInitApp();
  }, [student.id]);

  /* -------------------------------------------------------------------------- */
  /*                             SNAPSHOT Inscribed                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    dispatch(setLoadingRequestInTransit(false));
    if (inscribedSubjects === null) {
      localStorage.removeItem("initialSnapshot");
    }
    const snapshot = localStorage.getItem("initialSnapshot");
    if (!snapshot && inscribedSubjects) {
      localStorage.setItem("initialSnapshot", JSON.stringify(inscribedSubjects));
    }
  }, [inscribedSubjects]);

  const { md, lg } = useMediaQueries();

  /* -------------------------------------------------------------------------- */
  /*                          DESKTOP Layout & Routing                          */
  /* -------------------------------------------------------------------------- */
  if (lg) {
    return (
      <BrowserRouter>
        <DesktopLayout sessionExpired={sessionExpired}>
          <WebsocketManager />
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DesktopMain />} />
            <Route path="/bloqueo" element={<BlockedPage />} />
            <Route path="/usuario" element={<UnknownUserPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer />
        </DesktopLayout>
      </BrowserRouter>
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                        Mobile set OFF, only desktop                        */
  /* -------------------------------------------------------------------------- */
  // return (
  //   <BrowserRouter>
  //     <Routes>
  //       <Route element={<QuitViewScreen />} path="/" />
  //       <Route path="*" element={<Navigate to="/" />} />
  //       {/* </Route> */}
  //     </Routes>
  //   </BrowserRouter>
  // );

  /* -------------------------------------------------------------------------- */
  /*                           MOBILE Layout & Routing                          */
  /* -------------------------------------------------------------------------- */
  return (
    <BrowserRouter>
      <MobileLayout sessionExpired={sessionExpired}>
        <WebsocketManager />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/mis-cursos" element={<MobileCursos />} />
          {student.mostrarHorarios && <Route path="/calendario" element={<MobileCalendario />} />}
          <Route path="/bloqueo" element={<BlockedPage />} />
          <Route path="/usuario" element={<UnknownUserPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <ToastContainer />
      </MobileLayout>
    </BrowserRouter>
  );
}

export default withOneTabEnforcer({
  appName: "di-tella-app",
  OnlyOneTabComponent: Warning,
})(App);
